<template>
  <SidePannel :uid="uid" :show="show" @close="$emit('close-selector')">
    <h1 :id="`${uid}-header`">Select a Match</h1>
    <p>
      <VisibleText
        >Select a question from our global database to match with your question.
      </VisibleText>
    </p>
    <div class="dataset-benchmarks-selector__user-q">
      <span class="dataset-benchmarks-selector__subtitle">
        <VisibleText>Your Question</VisibleText>
      </span>
      {{ clientQuestion.question_title }} - {{ clientQuestion.question_text }}
    </div>

    <template v-if="statusBenchmarkGroupAvailable">
      <form
        class="dataset-benchmarks-selector__search forms__search"
        @submit.prevent
      >
        <label class="forms__search-label" for="global-search">
          <span class="visually-hidden">
            <VisibleText>Search global questions</VisibleText>
          </span>
          <SvgIconDecorative icon="search" />
        </label>
        <input
          type="text"
          name="forms__global-search"
          class="forms__search-input visible-text__placeholder"
          id="global-search"
          :placeholder="visiblePlaceholder"
          v-model="globalQuestionSearch"
        />
      </form>

      <form class="dataset-benchmarks-selector__form" @submit.prevent>
        <fieldset
          class="form-fieldset-group dataset-benchmarks-selector__fieldset light-scroll-bar"
          id="global-questions-group"
        >
          <legend class="form-fieldset-group__legend sr-only">
            <VisibleText>select a global question match</VisibleText>
          </legend>
          <div
            class="dataset-benchmarks-selector__radio-wrapper"
            v-for="globalQuestion in filteredGlobalQuestions"
            :key="`select-${globalQuestion._id.$oid}`"
          >
            <label
              class="form-radio-label dataset-benchmarks-selector__radio-label"
              :for="`gq-${globalQuestion._id.$oid}`"
            >
              <input
                class="form-radio-input"
                type="radio"
                :id="`gq-${globalQuestion._id.$oid}`"
                :name="globalQuestion._id.$oid"
                :value="globalQuestion._id.$oid"
                v-model="selectedMatch"
              />
              <span class="form-radio-selector"></span>
              <span class="dataset-table__match-title">
                {{ globalQuestion.question_title }}
              </span>
              <span class="dataset-table__match-text">
                {{ globalQuestion.question_text }}
              </span>
            </label>
          </div>
        </fieldset>
        <button
          type="submit"
          class="side-pannel__primary-btn"
          :disabled="!$pigeonline.permissions.hasPermission('update-dataset')"
          @click="submitMatch"
        >
          <VisibleText>submit match</VisibleText>
        </button>
        <button
          type="button"
          class="side-pannel__close-btn"
          @click="$emit('close-selector')"
        >
          <VisibleText>close</VisibleText>
        </button>
      </form>
    </template>

    <template v-else>
      <p class="error-msg__inline" style="margin: 2rem 0;">
        <VisibleText>Please select a benchmark group (step 3)</VisibleText>
      </p>
      <button
        type="button"
        class="side-pannel__primary-btn"
        @click="$emit('close-selector')"
      >
        <VisibleText>close</VisibleText>
      </button>
    </template>
  </SidePannel>
</template>

<script>
// Components
import SidePannel from "@/components/UI/SidePannel.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetBenchmarksSelector",
  mixins: [DatasetDetailsMixin],
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    uid: {
      default: () => "selector",
      type: String
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  components: {
    SidePannel,
    SvgIconDecorative
  },
  data() {
    return {
      globalQuestionSearch: null,
      selectedMatch: null
    }
  },
  computed: {
    filteredGlobalQuestions() {
      const keys = ["question_title", "question_text"]
      return this.$options.filters.searchInObjectValues(
        this.globalQuestions,
        keys,
        this.globalQuestionSearch
      )
    },
    statusBenchmarkGroupAvailable() {
      const benchmarkGroup = this.$store.getters[
        "datasetWizard/getBenchmarkGroup"
      ]
      return benchmarkGroup && Object.keys(benchmarkGroup).length > 0
    },
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "Search global questions",
        "forms__global-search",
        this.$store.getters.getLanguage
      )
    }
  },
  methods: {
    submitMatch() {
      if (!this.selectedMatch) return
      if (this.clientQuestion.benchmarking_id)
        this.updateMatch(this.clientQuestion, this.selectedMatch)
      else {
        const suggestedMatch =
          this.suggestedMatches[this.clientQuestion._id.$oid] || ""
        this.newMatch(this.clientQuestion, this.selectedMatch, suggestedMatch)
      }
      this.$emit("close-selector")
    }
  }
}
</script>
